<template>
    <div class="faq">
        <Header />
        <div class="banner">
            <div class="container">
                <div class="title">HOW TO SELL YOUR CAR ONLINE</div>
            </div>
        </div>
        <div class="main">

            <div class="container">

                <div class="warpper">

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h1>HOW TO SELL YOUR CAR ONLINE</h1>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>Long gone are the days when lengthy documentation and paperwork was vital for selling out
                                your vehicle. With the advancement of technology and digitalisation, the automotive business
                                has hit a new high. The global world is making huge profits by selling their cars online in
                                the snap of fingers. Here are the top 4 tips if you want to become one of them.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>FIND A SUITABLE ONLINE PLATFORM</h3>
                            <p>If you want to attract a great deal of audience and avoid unnecessary spammers, you should
                                log on to craigslist. It is a renowned online marketer, and you can start by filling in car
                                details.</p>
                            <p>Moreover, if you don't mind, you can also consider eBay motors. To increase traffic for your
                                product, you can put the car profile on Autotrader, Cargurus, Truecar, Cars.com and
                                Autotempest. Posting the car details on multiple websites exponentially increases the
                                chances of getting the desired deal for your car.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>THOROUGH ASSESSMENT OF THE VEHICLE</h3>
                            <p>You cannot sell the product without proper knowledge and understanding of it. So is the case
                                with your car. Dig in the details and market the worth of your car. Recall any
                                modifications, number of owners, registration papers, and enhancements because they will
                                likely improve your car value. Look for mileage, damages, recent repairs, previous history
                                of accidents because they will likely reduce the worth. Filling in the exact details of the
                                car on online value assessment websites will give out the estimated market value of your
                                car. Keeping this estimate in your mind is crucial as one can either rip you off by offering
                                a low price, or you can hit the bull’s eye by selling your car at a much greater profit.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>MAKE AN APPEALING LISTING AND POST PHOTOS</h3>
                            <p>Preferably, on a bright sunny day, take your car out on a driveway and wash its exterior and
                                interior thoroughly. Click some cinematic photos of your car front, side, and rear to
                                attract potential buyers. Put a thumbnail of the most elegant shot of your car online.
                                Congratulations, you have cracked half of the code to sell your car online. Now, post a
                                picture of any special modification or eye-catching stuff that you think might pull the
                                audience towards your product. For instance, car monogram, a well-cleaned engine, trunk, low
                                profile tires, neon lights etc. Showing service records is another way to build trust with
                                your customers. This adds to the grace of your product.</p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>CONVINCING NEGOTIATION AND ART OF PERSUADING</h3>
                            <p>There has not been a single business where the art of negotiation does not play its role. The
                                same goes for the car business. If you are fluent and confident with your words, there's not
                                a chance that you would not ace with your business model. The fair market value concept
                                comes into play for selling out used cars. Consumers can easily distinguish the car with no
                                accidental history and intact body compared to the one with scratches and regular visits to
                                a workshop. They can see the worth of the product that you are offering, so it is never
                                advisable to go significantly low for your car price when your product is rare to find.
                                Stick to your guns and wait for the right opportunity. Potential buyers are roaming around
                                to strike a worthwhile deal. For happy deals feel free to <router-link  to="/">contact</router-link> us we will be happy to
                                assist.</p>

                        </div>
                    </div>

                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
};
</script>
  
<style scoped lang="less">
.ddRow {
    font-family: Ubuntu-Regular, Ubuntu;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 1% ;
    margin-bottom: 36px;
    width: 100%;
}

.ddRow h2,
.ddRow h1,
.ddRow h3 {
    font-weight: bold;
    color: #004AAD;
    margin: 4% 0 2% 0;
    text-align: center;
}

.ddRow ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 2%;
    margin-left: 4%;
}

.ddRow img {
    width: 100%;
}

.ddCol-12 {
    width: 100%;
}

.ddCol-6 {
    width: 50%;
    padding: 2%;
    float: left;
}

.ddRow:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-device-width: 960px) {
    .ddRow {
        display: block;
    }

    .ddCol-6 {
        width: 100%;
    }
}

.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 180px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  